import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebaseApp';
import { collection, getDocs } from 'firebase/firestore';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Box,
  Button,
  Chip,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

// Componente Filtros
const Filtros = ({
  onFilterChange,
  categories,
  colors,
  measures,
  isMobile,
  navigate
}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]); // Filtro para Activo/Inactivo

  useEffect(() => {
    // Llamamos a onFilterChange cuando las categorías, colores, medidas o estado cambian
    onFilterChange({
      categories: selectedCategories,
      colors: selectedColors,
      measures: selectedMeasures,
      status: selectedStatus,  // Nuevo filtro
    });
  }, [selectedCategories, selectedColors, selectedMeasures, selectedStatus]);

  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    setSelectedCategories((prev) =>
      checked ? [...prev, value] : prev.filter((category) => category !== value),
    );
  };

  const handleColorChange = (event) => {
    const { value, checked } = event.target;
    setSelectedColors((prev) =>
      checked ? [...prev, value] : prev.filter((color) => color !== value),
    );
  };

  const handleMeasureChange = (event) => {
    const { value, checked } = event.target;
    setSelectedMeasures((prev) =>
      checked ? [...prev, value] : prev.filter((measure) => measure !== value),
    );
  };

  const handleStatusChange = (event) => {
    const { value, checked } = event.target;
    setSelectedStatus((prev) =>
      checked ? [...prev, value] : prev.filter((status) => status !== value),
    );
  };

  const filterContent = (
    <Box sx={{ p: 2, backgroundColor: isMobile ? 'transparent' : '#D9D9D9' }}>
      <Typography variant="h6" sx={{ fontFamily: 'Inter', color: '#676767' }}>
        Filtrar
      </Typography>

      <FormGroup>
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Inter', color: '#185D22' }}
        >
          Categorías
        </FormLabel>
        {categories.map((category) => (
          <FormControlLabel
            key={category.id}
            control={
              <Checkbox
                value={category.name}
                onChange={handleCategoryChange}
                checked={selectedCategories.includes(category.name)}
              />
            }
            label={category.name}
          />
        ))}
      </FormGroup>

      <FormGroup sx={{ mt: 2 }}>
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Inter', color: '#185D22' }}
        >
          Color
        </FormLabel>
        {colors.map((color) => (
          <FormControlLabel
            key={color}
            control={
              <Checkbox
                value={color}
                onChange={handleColorChange}
                checked={selectedColors.includes(color)}
              />
            }
            label={color}
          />
        ))}
      </FormGroup>

      <FormGroup sx={{ mt: 2 }}>
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Inter', color: '#185D22' }}
        >
          Medidas (ancho x alto)
        </FormLabel>
        {measures.map((measure) => (
          <FormControlLabel
            key={measure}
            control={
              <Checkbox
                value={measure}
                onChange={handleMeasureChange}
                checked={selectedMeasures.includes(measure)}
              />
            }
            label={measure}
          />
        ))}
      </FormGroup>

      {/* Filtro para Activo/Inactivo */}
      <FormGroup sx={{ mt: 2 }}>
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Inter', color: '#185D22' }}
        >
          Estado
        </FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              value="Activo"
              onChange={handleStatusChange}
              checked={selectedStatus.includes("Activo")}
            />
          }
          label="Activo"
        />
        <FormControlLabel
          control={
            <Checkbox
              value="Inactivo"
              onChange={handleStatusChange}
              checked={selectedStatus.includes("Inactivo")}
            />
          }
          label="Inactivo"
        />
      </FormGroup>

      <FormGroup sx={{ mt: 2 }}>
        <Button variant="contained"
            sx={{backgroundColor :'#185D22'}}
            onClick={()=> navigate('/NuevoStandar')} >
          Nuevo Producto
        </Button>
      </FormGroup>
    </Box>
  );

  if (isMobile) {
    return filterContent;
  }

  return <Box>{filterContent}</Box>;
};

// Componente Listado de Productos
const ProductList = ({ products , navigate }) => {

  const handleEdit = (productId) => {
    navigate('/NuevoStandar', {
      state: {
        objeto: productId,
      },
    });
  };

  return (
    <Grid container spacing={2}>
      {products.map((product) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
          <Card id={'CARD'}>
            <CardMedia
              component="img"
              height="250"
              image={product.fotoPrincipal}
              alt={product.titulo}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {product.titulo}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1em' }}>
                {`${product.medidas}`}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '1.5rem', color: 'red' }}>
                {`$${product.precio.toLocaleString()}`}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '1rem', color: 'red' }}>
                {`Stock: ${product.stock}`}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '1rem' }}>
                {`Estado: ${product.activo ? 'Activo' : 'Inactivo'}`}
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ fontSize: '14px' }}>
                  {`Colores disponibles:`}
                </Typography>
                {product.colores.map((color, index) => (
                  <Chip
                    key={index}
                    label={color.nombre}
                    sx={{
                      backgroundColor: '#185D22',
                      color: 'white',
                      mr: 1,
                    }}
                  />
                ))}
              </Box>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => handleEdit(product.id)}
              >
                Editar
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

// Componente Principal de la Pantalla de Productos
const ProductsPage = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'productos'));
      const productsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setProducts(productsData);
      setFilteredProducts(productsData);

      const uniqueColors = [
        ...new Set(
          productsData.flatMap((product) =>
            product.colores.map((c) => c.nombre),
          ),
        ),
      ];
      setColors(uniqueColors);

      const uniqueMeasures = [
        ...new Set(productsData.map((product) => product.medidas)),
      ];
      setMeasures(uniqueMeasures);
    };

    const fetchCategories = async () => {
      const categoriesSnapshot = await getDocs(collection(db, 'categoriesStandarProducts'));
      const categoriesData = categoriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setCategories(categoriesData);
    };

    fetchProducts();
    fetchCategories();
  }, []);

  const handleFilterChange = useCallback((filters) => {
    const filtered = products.filter((product) => {
      const matchCategory =
        filters.categories.length === 0 ||
        filters.categories.includes(product.categoria);

      const matchColor =
        filters.colors.length === 0 ||
        product.colores.some((color) => filters.colors.includes(color.nombre));

      const matchMeasure =
        filters.measures.length === 0 ||
        filters.measures.includes(product.medidas);

      const matchStatus =
        filters.status.length === 0 ||
        (filters.status.includes("Activo") && product.activo) ||
        (filters.status.includes("Inactivo") && !product.activo);

      return matchCategory && matchColor && matchMeasure && matchStatus;
    });

    setFilteredProducts(filtered);
  }, [products]);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Grid container spacing={2}>
      {!isMobile && (
        <Grid item xs={12} md={2}>
          <Filtros
            onFilterChange={handleFilterChange}
            categories={categories}
            colors={colors}
            measures={measures}
            isMobile={false}
            navigate={navigate}
          />
        </Grid>
      )}
      <Grid item xs={12} md={10}>
        {isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <IconButton color="primary" onClick={handleOpenDialog}>
              <FilterListIcon />
            </IconButton>
          </Box>
        )}
        <ProductList products={filteredProducts}  navigate={navigate}/>
      </Grid>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        fullScreen={isMobile}
      >
        <DialogTitle>Filtrar Productos</DialogTitle>
        <DialogContent>
          <Filtros
            onFilterChange={handleFilterChange}
            categories={categories}
            colors={colors}
            measures={measures}
            isMobile={true}
            navigate={navigate}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained" onClick={handleCloseDialog}>
              Cerrar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ProductsPage;
