import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebaseApp';
import { collection, getDocs } from 'firebase/firestore';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

// Componente Filtros con Categorías y Tipos
const Filters = ({ onFilterChange, categories, isMobile }) => {
  const [selectedTypes, setSelectedTypes] = useState([]);

  const handleTypeChange = (event) => {
    const { value, checked } = event.target;
    const updatedTypes = checked
      ? [...selectedTypes, value]
      : selectedTypes.filter(type => type !== value);
    setSelectedTypes(updatedTypes);
    onFilterChange(updatedTypes);
  };

  return (
    <Box sx={{ p: 2, backgroundColor: isMobile ? 'transparent' : '#D9D9D9' }}>
      <Typography variant="h6" sx={{ fontFamily: 'Inter', color: '#676767' }}>Filtrar por Tipo</Typography>
      {categories.map((category) => (
        <Box key={category.id}>
          <FormLabel component="legend" sx={{ fontFamily: 'Inter', color: '#185D22', mt: 2 }}>
            {category.name}
          </FormLabel>
          <FormGroup>
            {category.types.map((type) => (
              <FormControlLabel
                key={type.name}
                control={
                  <Checkbox
                    value={type.name}
                    onChange={handleTypeChange}
                    checked={selectedTypes.includes(type.name)}
                  />
                }
                label={type.name}
              />
            ))}
          </FormGroup>
        </Box>
      ))}
    </Box>
  );
};

// Componente que lista los colores filtrados
const ColorList = ({ selectedTypes, categories }) => {
  // Filtrar los colores en base a los tipos seleccionados
  const filteredColors = categories.flatMap(category =>
    category.types
      .filter(type => selectedTypes.includes(type.name))
      .flatMap(type => type.colors)
  );

  return (
    <Grid container spacing={2}>
      {filteredColors.map((color, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardMedia
              component="img"
              height="250"
              image={color.photoUrl}
              alt={color.name}
            />
            <CardContent>
              <Typography variant="h6" component="div">
                {color.name}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

// Componente Principal
const CatalogeCategoriesClientPage = () => {
  const [categories, setCategories] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchCategories = async () => {
      const catSnapshot = await getDocs(collection(db, 'CategoriasDeCatalogo'));
      const loadedCategories = catSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        types: doc.data().types || []
      }));
      setCategories(loadedCategories);
    };

    fetchCategories();
  }, []);

  const handleFilterChange = useCallback((newSelectedTypes) => {
    setSelectedTypes(newSelectedTypes);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Filters
          onFilterChange={handleFilterChange}
          categories={categories}
          isMobile={isMobile}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <ColorList
          selectedTypes={selectedTypes}
          categories={categories}
        />
      </Grid>
    </Grid>
  );
};

export default CatalogeCategoriesClientPage;
