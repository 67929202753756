import { db, storage } from '../../firebaseApp';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  deleteDoc,
  getDocs,
} from 'firebase/firestore';
import {
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Radio,
  RadioGroup,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

const ProductForm = () => {
  const location = useLocation();
  const productId = location?.state?.objeto;
  const navigate = useNavigate();

  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [precio, setPrecio] = useState(0);
  const [stock, setStock] = useState(0);
  const [activo, setActivo] = useState(true);
  const [medidas, setMedidas] = useState('');
  const [categoria, setCategoria] = useState('');
  const [categorias, setCategorias] = useState([]); 
  const [fotos, setFotos] = useState([]); // Almacena URLs de Firebase
  const [fotosFiles, setFotosFiles] = useState([]); // Almacena archivos a subir
  const [fotosPreviews, setFotosPreviews] = useState([]); // Previsualización local
  const [fotoPrincipalIndex, setFotoPrincipalIndex] = useState(0);
  const [colores, setColores] = useState([
    { nombre: '', imagen: null, preview: null },
  ]);
  const [loading, setLoading] = useState(false); 
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

  useEffect(() => {
    const fetchCategories = async () => {
      const categoriesSnapshot = await getDocs(
        collection(db, 'categoriesStandarProducts'),
      );
      const categoriesData = categoriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategorias(categoriesData);
    };

    fetchCategories();

    if (productId) {
      const loadProduct = async () => {
        setLoading(true);
        const productRef = doc(db, 'productos', productId);
        const productSnap = await getDoc(productRef);
        if (productSnap.exists()) {
          const productData = productSnap.data();
          setTitulo(productData.titulo);
          setDescripcion(productData.descripcion);
          setPrecio(productData.precio);
          setStock(productData.stock);
          setActivo(productData.activo);
          setMedidas(productData.medidas);
          setCategoria(productData.categoria);
          setFotos(productData.fotos); // Cargar URLs de Firebase
          setFotosPreviews(productData.fotos); // Mostrar las imágenes actuales para previsualización
          setFotoPrincipalIndex(
            productData.fotos.findIndex(
              (url) => url === productData.fotoPrincipal,
            ),
          );
          setColores(
            productData.colores.map((color) => ({
              nombre: color.nombre,
              imagen: null, 
              preview: color.imagen,
            })),
          );
        }
        setLoading(false);
      };

      loadProduct();
    }
  }, [productId]);

  const handleFotoChange = (e) => {
    const files = Array.from(e.target.files);
    const newFotosPreviews = files.map((file) => URL.createObjectURL(file));
    setFotosFiles([...fotosFiles, ...files]);
    setFotosPreviews([...fotosPreviews, ...newFotosPreviews]);
  };

  const handleRemoveFoto = (index) => {
    const newFotos = fotos.filter((_, i) => i !== index);
    const newFotosFiles = fotosFiles.filter((_, i) => i !== index);
    const newFotosPreviews = fotosPreviews.filter((_, i) => i !== index);
    setFotos(newFotos);
    setFotosFiles(newFotosFiles);
    setFotosPreviews(newFotosPreviews);
    if (index === fotoPrincipalIndex) {
      setFotoPrincipalIndex(0);
    } else if (index < fotoPrincipalIndex) {
      setFotoPrincipalIndex(fotoPrincipalIndex - 1);
    }
  };

  const handleColorChange = (index, e) => {
    const newColores = [...colores];
    if (e.target.name === 'nombre') {
      newColores[index].nombre = e.target.value;
    } else {
      const file = e.target.files[0];
      newColores[index].imagen = file;
      newColores[index].preview = URL.createObjectURL(file);
    }
    setColores(newColores);
  };

  const handleAddColor = () => {
    setColores([...colores, { nombre: '', imagen: null, preview: null }]);
  };

  const handleRemoveColor = (index) => {
    const newColores = colores.filter((_, i) => i !== index);
    setColores(newColores);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const fotosUrls = await Promise.all(
        fotosFiles.map(async (foto) => {
          const fotoRef = ref(storage, `productos/${foto.name}`);
          await uploadBytes(fotoRef, foto);
          return getDownloadURL(fotoRef);
        }),
      );

      const allFotosUrls = [...fotos, ...fotosUrls];

      const coloresData = await Promise.all(
        colores.map(async (color) => {
          if (color.imagen) {
            const colorRef = ref(storage, `colores/${color.imagen.name}`);
            await uploadBytes(colorRef, color.imagen);
            const imagenUrl = await getDownloadURL(colorRef);
            return { nombre: color.nombre, imagen: imagenUrl };
          } else {
            return { nombre: color.nombre, imagen: color.preview };
          }
        }),
      );

      if (productId) {
        const productRef = doc(db, 'productos', productId);
        await updateDoc(productRef, {
          titulo,
          descripcion,
          precio: Number(precio),
          stock: Number(stock),
          activo,
          medidas,
          categoria,
          fotos: allFotosUrls,
          fotoPrincipal: allFotosUrls[fotoPrincipalIndex],
          colores: coloresData,
        });
        setAlert({ open: true, severity: 'success', message: 'Producto actualizado con éxito!' });
      } else {
        await addDoc(collection(db, 'productos'), {
          titulo,
          descripcion,
          precio: Number(precio),
          stock: Number(stock),
          activo,
          medidas,
          categoria,
          fotos: allFotosUrls,
          fotoPrincipal: allFotosUrls[fotoPrincipalIndex],
          colores: coloresData,
        });
        setAlert({ open: true, severity: 'success', message: 'Producto guardado con éxito!' });
      }

      navigate('/NuevoStandarList');
    } catch (error) {
      setAlert({ open: true, severity: 'error', message: `Ocurrió un error al guardar el producto: ${error.message}` });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    // @ts-ignore
    if (window.confirm('¿Estás seguro de que deseas eliminar este producto? Esta acción no se puede deshacer.')) {
      try {
        setLoading(true);

        await Promise.all(
          fotos.map(async (url) => {
            const fotoRef = ref(storage, url);
            await deleteObject(fotoRef);
          }),
        );

        const productRef = doc(db, 'productos', productId);
        await deleteDoc(productRef);

        setAlert({ open: true, severity: 'success', message: 'Producto eliminado con éxito!' });

        navigate('/NuevoStandarList');
      } catch (error) {
        setAlert({ open: true, severity: 'error', message: `Ocurrió un error al eliminar el producto: ${error.message}` });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Typography variant="h5" gutterBottom>
        {productId ? 'Editar Producto Estandar' : 'Crear Nuevo Producto Estandar'}
      </Typography>

      <TextField
        fullWidth
        label="Título"
        value={titulo}
        // @ts-ignore
        onChange={(e) => setTitulo(e.target.value)}
        required
        margin="normal"
      />

      <TextField
        fullWidth
        label="Descripción"
        value={descripcion}
        // @ts-ignore
        onChange={(e) => setDescripcion(e.target.value)}
        required
        margin="normal"
        multiline
        rows={4}
      />

      <TextField
        fullWidth
        label="Precio"
        type="number"
        value={precio}
        // @ts-ignore
        onChange={(e) => setPrecio(e.target.value)}
        required
        margin="normal"
      />

      <TextField
        fullWidth
        label="Stock"
        type="number"
        value={stock}
        // @ts-ignore
        onChange={(e) => setStock(e.target.value)}
        required
        margin="normal"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={activo}
            // @ts-ignore
            onChange={(e) => setActivo(e.target.checked)}
            color="primary"
          />
        }
        label="Activo"
        sx={{ mt: 2 }}
      />

      <TextField
        fullWidth
        label="Medidas"
        value={medidas}
        // @ts-ignore
        onChange={(e) => setMedidas(e.target.value)}
        required
        margin="normal"
      />

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="categoria-label">Categoría</InputLabel>
        <Select
          labelId="categoria-label"
          value={categoria}
          label="Categoría"
          // @ts-ignore
          onChange={(e) => setCategoria(e.target.value)}
          required
        >
          {categorias.map((categoria) => (
            <MenuItem key={categoria.id} value={categoria.name}>
              {categoria.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Fotos del Producto
        </Typography>
        <Button variant="contained" component="label">
          Subir Fotos
          <input type="file" multiple hidden onChange={handleFotoChange} />
        </Button>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {fotosPreviews.map((url, index) => (
            <Grid item xs={4} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={url}
                  alt={`Foto ${index + 1}`}
                />
                <CardContent>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="foto-principal"
                      name="foto-principal"
                      value={fotoPrincipalIndex}
                      onChange={() => setFotoPrincipalIndex(index)}
                    >
                      <FormControlLabel
                        value={index}
                        control={<Radio />}
                        label="Principal"
                      />
                    </RadioGroup>
                  </FormControl>
                </CardContent>
                <CardActions>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveFoto(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Colores del Producto
        </Typography>
        {colores.map((color, index) => (
          <Box
            key={index}
            sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
          >
            <TextField
              label="Nombre del color"
              name="nombre"
              value={color.nombre}
              onChange={(e) => handleColorChange(index, e)}
              required
              sx={{ mr: 2 }}
            />
            <Button variant="contained" component="label" sx={{ mr: 2 }}>
              Subir Imagen
              <input
                type="file"
                hidden
                name="imagen"
                onChange={(e) => handleColorChange(index, e)}
              />
            </Button>
            {color.preview && (
              <Card sx={{ width: 100 }}>
                <img
                  src={color.preview}
                  height={45}
                  width={45}
                  style={{
                    borderRadius: '50%',
                  }}
                />
                <CardActions>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveColor(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            )}
          </Box>
        ))}
        <IconButton color="primary" onClick={handleAddColor} sx={{ mt: 2 }}>
          <AddCircleOutlineIcon />
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          Agregar otro color
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : productId ? (
            'Actualizar Producto'
          ) : (
            'Guardar Producto'
          )}
        </Button>
        {productId && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ ml: 2 }}
            onClick={handleDelete}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Eliminar Producto'}
          </Button>
        )}
      </Box>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          // @ts-ignore
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProductForm;
